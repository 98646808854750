<template>
  <div class="container_con">
    <el-card>
      <div class="new_search_box">
        <div>
          <span class="mr_20">
            产品编号：
            <el-input
              v-model="queryParams.product_no"
              placeholder="请输入产品编号"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            产品名称：
            <el-input
              v-model="queryParams.product_name"
              placeholder="请输入产品名称"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            生产厂家：
            <el-input
              v-model="queryParams.manufacturer"
              placeholder="请输入生产厂家"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            批号：
            <el-input
              v-model="queryParams.batch_number"
              placeholder="请输入批号"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            有效期：
            <el-date-picker
              v-model="queryParams.valid_until"
              type="date"  value-format="YYYY-MM-DD" 
              placeholder="请选择时间"
            >
            </el-date-picker>
          </span>
          <el-button type="primary" @click="handleQuery()">搜索</el-button>
          <el-button @click="resetQuery()">重置</el-button>
        </div>
      </div>
      <div class="search">
          <div>
              <el-button type="primary" icon="el-icon-download" v-if="have_auth('/inventory/import')" @click="try_import">导入</el-button>
              <el-button type="primary" v-if="have_auth('/inventory/export')" :loading="is_export" @click="try_export">导出</el-button>
          </div>
      </div>
      <div style="margin-top:20px">
        
        <el-table :data="list" v-loading="loading" style="width: 100%">
          <el-table-column prop="product_no" label="产品编号" width="120" />
          <el-table-column prop="product_name" label="品名" />
          <el-table-column prop="spec_value_name" label="规格" />
          <el-table-column prop="manufacturer" label="生产厂家" />
          <el-table-column prop="pack_unit_name" label="包装单位" />
          <el-table-column prop="plan_num" label="计划占用" />
          <el-table-column prop="stock" label="可开数量" />
          <el-table-column prop="actual_stock" label="实际库存" />
          <el-table-column prop="batch_number" label="批号" />
          <el-table-column prop="price" label="成本单价（元）" width="120" />
          <el-table-column prop="total_stock_price" label="库存金额（元）" width="120" />
          <el-table-column prop="origin" label="产地" />
          <el-table-column prop="valid_until"  label="有效期">
              <template v-slot="scope">
                <span >{{scope.row.valid_until || '长效期'}}</span>
              </template>
          </el-table-column>
          <!-- <el-table-column prop="manufacturer" label="生产厂家" /> -->
        </el-table>
      </div>
    </el-card>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
  <importmodel ref="importmodel" @handleUploadSuccess="getList" />
</template>

<script>
import importmodel from "@/components/import";
export default {
  name: "inventory",
  components: {
      importmodel
  },
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          time:''
      },
      total:0,
      list:[],
      is_export:false
    };
  },
  created() {
      this.queryParams=window[this.$route.path] || {
        page: 1,
        size: 10,
        time:''
      }
      this.getList();
  },
  watch: {
    $route(to,form) {
        window[form.path]=this.queryParams
    },
  },
  methods: {
      try_import(){
            this.$refs.importmodel.init(3)
      },
      try_export(){
          if(this.is_export){return false;}
          this.is_export = true;
          this.$httpGet("/backend/ProductStock/export", this.queryParams).then((res) => {
              if (res.status == 200) {
                    var a = document.createElement('a');
                    let name=res.data.file.split('/')
                    var fileName = name[name.length-1];
                    a.download = fileName;
                    a.href = this.$http+'/'+res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
              } else {
                  this.$message.error(res.message);
              }
              this.is_export = false;
          }).catch((err) => {
              console.log(err);
              this.is_export = false;
          });
      },
      /** 查询列表 */
      getList() {
          this.loading = true;
          let _data={...this.queryParams};
          this.$httpGet("/backend/ProductStock/index",_data ).then((res) => {
              if (res.status == 200) {
                  this.loading = false;
                  this.list = res.data.data;
                  this.total = res.data.total;
              } else {
                  this.$message.error(res.message);
              }
              this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          });
      },
      /** 搜索按钮操作 */
      handleQuery() {
          this.queryParams.page = 1;
          this.getList();
      },
      resetQuery(){
          this.queryParams={
              page:1,
              size:10,
              time:''
          }
          this.getList();
      }
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  background: #f2f3f5;
  border-radius: 2px;
  padding: 15px;
}
.mr_20 {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}
</style>
